/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-07-31 10:50:26
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-06 10:59:52
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerSupplier = params => axios({
    url: '/api/dealer/supplier/dealerSupplier/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerSupplier = params => axios({
    url: '/api/dealer/supplier/dealerSupplier/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerSupplier = params => axios({
    url: '/api/dealer/supplier/dealerSupplier/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerSupplier = params => axios({
    url:'/api/dealer/supplier/dealerSupplier/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerSupplier = params => axios({
    url: '/api/dealer/supplier/dealerSupplier/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
