<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-07-31 10:50:26
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-06 10:57:09
-->
<template>
  <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit"
           okText="提交" :maskClosable="false">
    <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}"
                  :wrapper-col="{span:21}">
      <a-form-model-item label="供应商全称" prop="supplierName">
        <a-input v-model="rowData.supplierName" placeholder="供应商全称"></a-input>
      </a-form-model-item>
      <a-form-model-item label="供应商简称" prop="name">
        <a-input v-model="rowData.name" placeholder="供应商简称"></a-input>
      </a-form-model-item>
      <a-form-model-item label="供应商编码" prop="supplierCode">
        <a-input v-model="rowData.supplierCode" placeholder="供应商编码"></a-input>
      </a-form-model-item>
      <a-form-model-item label="K3编码" prop="k3Code">
        <a-input v-model="rowData.k3Code" placeholder="K3编码"></a-input>
      </a-form-model-item>
      <a-form-model-item label="供应商折扣" prop="supplierDiscount">
        <a-input v-model="rowData.supplierDiscount" placeholder="供应商折扣"></a-input>
      </a-form-model-item>
      <a-form-model-item label="开户银行" prop="depositBank">
        <a-input v-model="rowData.depositBank"></a-input>
      </a-form-model-item>
      <a-form-model-item label="银行账户" prop="bankAccount">
        <a-input v-model="rowData.bankAccount"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {editDealerSupplier, selectByIdDealerSupplier, addDealerSupplier} from '../api/DealerSupplierApi'

export default {
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      // 表单验证
      formRule: {
        supplierName: [
          {required: true, message: '请输入供应商全称', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '请输入供应商简称', trigger: 'blur'}
        ],
        supplierDiscount: [
          {required: true, message: '请输入供应商折扣', trigger: 'blur'}
        ],
        supplierCode: [
          {required: true, message: '请输入供应商编码', trigger: 'blur'}
        ],
        k3Code: [
          {required: true, message: '请输入K3编码', trigger: 'blur'}
        ],
        depositBank: [
          {required: true, message: '请输入供应商开户银行', trigger: 'blur'}
        ],
        bankAccount: [
          {required: true, message: '请输入供应商银行账户', trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = {}
      if(handle == 'edit') {
        selectByIdDealerSupplier(row.id).then(res => {
          this.rowData = res.body
        })
      }
    },
    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return
        }
        const res = this.handle === 'add' ? await addDealerSupplier(this.rowData) : await editDealerSupplier(this.rowData)
        if (res.code === 200) {
          this.$notification.success({message: res.message})
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
        } else {
          this.$notification.error({message: res.message})
        }
      })
    }
  },
  created() {

  },

}
</script>

<style lang="less" scoped>

</style>
